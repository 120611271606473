.service-container {
  padding: 20px;
  margin-top: 22px;
}
.service-box {
  width: 90%;
  height: 70px;
  background-color: #004468;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 15px;
  color: white;
}

.service-description {
  margin: 0;
  font-size: 16px;
}

@media (max-width: 768px) {
  .service-box {
    height: 90px;
  }

  .service-description {
    font-size: 15px;
  }
}
@media screen and (max-width: 567px) {
  .service-box {
    width: 100%;
    height: 68px;
  }
}

.hero-section {
  background-image: url("../../Assets/Images/bg.svg");
  background-size: cover;
  background-position: center;
  padding: 20px;
  position: relative;
  height: 100vh;
  display: flex;
  align-items: flex-end;
  color: white;
}

.hero-content {
  margin-bottom: 20px;
}

.hero-content h2 {
  font-size: 5rem;
  margin-bottom: 20px;
}

.view-services-button {
  background-color: transparent;
  border: 1px solid white;
  color: white;
  margin-bottom: 20px;
  height: 55px;
  width: 220px;
}

.counts {
  display: flex;
}

.counts h2 {
  font-size: 2.3rem;
}
.counts p {
  font-size: 1.5rem;
}


@media screen and (max-width: 1024px) {
  .hero-section{
    height: 70vh;
  }
  .view-services-button {
    height: 40px;
  width: 155px;
  }
}

@media screen and (max-width: 820px) {
  .hero-section{
    height: 80vh;
  }
}

@media screen and (max-width: 567px) {
  .hero-section{
    height: 95vh;
  }
  .view-services-button {
    height: 40px;
  width: 160px;
  }

}

@media (max-width: 768px) {
  .hero-content {
    margin-bottom: 10px;
  }

  .hero-content h2 {
    font-size: 2.5rem;
  }

  .counts {
    flex-direction: column;
    align-items: flex-start;
    font-size: 2rem;
  }

  .counts h2 {
    font-size: 1.5rem;
  }
  .counts p {
    font-size: 1rem;
  }
}

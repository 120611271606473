.summary-container {
    margin-bottom: 150px;
    padding: 20px;
    border-radius: 8px;
}

.pie-container {
    margin-bottom: 40px;
}

.pie-container img{
    width:300px;
}

.timeline-container {
    display: flex;
    justify-content: center;
}
.timeline{
    width: 85%;
}

@media screen and (max-width: 1024px) {
    .pie-container img{
        width:250px;
    }
}

@media screen and (max-width: 820px) {
    .pie-container img{
        width:200px;
    }
}
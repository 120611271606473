
.tools-container {
  padding: 20px;
  margin-bottom: 150px;
}

.texts p {
  font-size: 18px;
  font-weight: 500;
}

.image-container {
  position: relative;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.img1 {
  display: block;
  width: 300px;
  height: 300px;
}

.img2 {
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translate(-50%, 50%);
  width: 300px;
  height: 300px;
}

.texts {
  padding: 20px;
}

@media screen and (max-width: 1024px) {
  .image-container {
    display: block; 
    text-align: center; 
    margin-bottom: 130px;
  }

  .img1 {
    display: block;
    width: 250px;
    height: 250px;
  }
  
  .img2 {
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translate(-50%, 50%);
    width: 250px;
    height: 250px;
  }
}

@media screen and (max-width: 820px) {
  .image-container {
    display: block; 
    text-align: center; 
    margin-bottom: 130px;
  }

  .img1 {
    display: block;
    width: 250px;
    height: 250px;
  }
  
  .img2 {
    position: absolute;
    bottom: 0;
    left: 60%;
    transform: translate(-50%, 50%);
    width: 250px;
    height: 250px;
  }
}

@media (max-width: 567px) {
  .tools-container {
    margin-bottom: 0px;
  }
  .tools-row {
    display: flex;
    flex-direction: column; 
  }

  .image-col {
    order: 1; 
  }

  .texts {
    order: 2;
  }

  .image-container {
    display: block; 
    text-align: center; 
    margin-bottom: 130px;
  }

  .img1 {
    display: block;
    width: 250px;
    height: 250px;
  }
  
  .img2 {
    position: absolute;
    bottom: 0;
    left: 58%;
    transform: translate(-50%, 50%);
    width: 250px;
    height: 250px;
  }
}

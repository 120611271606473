.statements-container {
  padding: 20px;
}

.statements-col {
  margin-bottom: 25px;
}

.statements-item {
  display: flex;
  align-items: flex-start;
  padding: 15px;
}

.statements-icon {
  padding: 5px;
  border-radius: 50%;
  margin-right: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
}

.statements-content {
  flex: 1; 
}

.statements-content p {
  margin-bottom: 0;
  margin-top: 5px;
  font-size: 18px;
}

@media (max-width: 768px) {
  .statements-icon {
    width: 40px;
    height: 40px;
    font-size: 20px;
  }

  .statements-content p {
    font-size: 17px;
  }
}

.service-row {
  margin-bottom: 150px;
  padding-bottom: 50px;
  display: flex;
  align-items: start;
}

.service-image img {
  width: 100%;
  height: auto;
}

.service-divider {
  border: 0;
  border-top: 1px solid #a2a1a1;
  margin-top: 1rem;
  margin-bottom: 1.5rem;
}

@media (max-width: 768px) {
  .service-row {
    flex-direction: column;
  }

  .service-image {
    margin-bottom: 20px;
  }
}

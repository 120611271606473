h3{
  font-size: 3rem;
}

@media screen and (max-width: 1024px) {
  h3{
    font-size: 2.3rem;
  }
}

@media screen and (max-width: 768px) {
  h3{
    font-size: 1.7rem;
  }
}

.about-section {
    margin: 5rem auto;
    padding-top: 20px;
  }
  
  .about-img-container {
    display: flex;
    justify-content: center;
  }
  
  .about-heading {
    font-size: 18px;
  }
 
  .about-desc {
    font-size: 20px;
    line-height: 1.6;
    margin-bottom: 2rem;
  }
  
  @media (max-width: 768px) {
    .about-section {
      flex-direction: column;
      align-items: flex-start; 
      text-align: start;
    }
  
    .about-img-container {
      margin-bottom: 1.5rem;
    }
  
    img {
      max-width: 80%;
      margin-bottom: 15px;
    }
    
    .about-desc {
      font-size: 18px;
    }
  
    .about-heading,
    .about-title,
    .about-desc,
    .btn {
      align-self: flex-start; 
      text-align: left;
    }
  }

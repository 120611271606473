.mission-container {
    padding: 20px;
  }
  
  .mission-item {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    height: 100%;
  }
  
  .mission-item img.icon {
    width: 50px;
    height: 50px;
    object-fit: contain;
  }
  
  .mission-item h4 {
    margin-top: 10px;
    margin-bottom: 10px;
    font-size: 24px;
  }
  .mission-item p {
    font-size: 18px;
  }
  
  @media (max-width: 768px) {
    .mission-item img.icon {
      width: 40px;
      height: 40px;
    }
  
    .mission-item h4 {
      font-size: 25px;

    }
  
    .mission-item p {
      font-size: 18px;
    }
  }
  
.clients-container {
  padding: 20px;
}

.client-logo {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
}

.client-image {
  width: 100px;
  height: 100px;
}

@media (max-width: 768px) {
  .client-logo {
    margin-bottom: 10px;
  }

  .client-image {
    width: 90px;
    height: 90px;
  }
}

@import "~bootstrap/dist/css/bootstrap.min.css";

/* Import Roboto font */
@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700&display=swap");

* {
  font-family: "Roboto", sans-serif;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body,div,h1,h2,h3,h4,h5,h6,p,span,a,button {
  font-family: "Roboto", sans-serif;
}

code {
  font-family: "Roboto", sans-serif;
}

.footer-section{
    padding: 40px 0px;
}
.footer-col {
    margin-bottom: 30px;
}

.footer-col h5 {
    font-size: 20px;
    font-weight: 600;
    margin-bottom: 28px;
}

.footer-col p {
    font-size: 17px;
    font-weight: 500;
}

.footer-icon {
    width: 30px;
    height: 30px;
}

/* Responsive styles */
@media (max-width: 768px) {
    .footer-col h5 {
        font-size: 16px;
    }

    .footer-col p {
        font-weight: 400;
        font-size: 12px;
    }

    .footer-icon {
        width: 20px;
        height: 20px;
    }

    .footer-section .row {
        flex-wrap: wrap;
    }

    .footer-section .col-md-4:first-child {
        width: 40%;
    }

    .footer-section .col-md-4:not(:first-child) {
        display: inline-block;
        width: 30%;
        padding: 0;
        text-align: start;
    }

}

@media (max-width: 576px) {
    .footer-col h5 {
        font-size: 18px;
    }

    .footer-col p {
        font-weight: 400;
        font-size: 14px;
    }
    
    .footer-section .col-md-4:first-child {
        width: 100%;
    }

    .footer-section .col-md-4:not(:first-child) {
        display: inline-block;
        width: 50%;
        padding: 0;
        text-align: left;
    }

   
    .footer-col{
        padding-left: 30px;
    }
}

.navbar {
  background-color: white;
  position: sticky; 
  top: 0;
  z-index: 1000;
  width: 100%;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.navbar .navbar-toggler {
  border-color: black;
}

.navbar .navbar-toggler-icon,
.navbar .navbar-brand img {
  filter: invert(0);
}

.navbar .navbar-brand img {
  transform: rotate(45deg);
  width: 1.5rem;
}

.navbar-nav .nav-link {
  margin-right: 1.8rem; 
}

@media screen and (max-width: 576px) {
  .navbar {
    background-color: white;
  }
}

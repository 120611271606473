.chooseUs-container {
    padding: 20px;
    margin-top: 22px;
    margin-bottom: 22px;
  }
 
  .chooseUs-item {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    height: 100%;
  }
  
  .chooseUs-item img.icon {
    width: 50px;
    height: 50px;
    object-fit: contain;
  }
  
  .chooseUs-item h4 {
    margin-top: 10px;
    margin-bottom: 10px;
    
  }
.chooseUs-item p{
  font-size: 18px;

}
  
  @media (max-width: 768px) {
    .chooseUs-item img.icon {
      width: 40px;
      height: 40px;
    }
  
    .chooseUs-item h4 {
      font-size: 1.25rem;
    }
  

  }
  